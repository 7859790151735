$(document).ready(function () {

  //PAGE ANIMATION EFFECT

  var options = {
    threshold: 0.0
  };


  var observer = new IntersectionObserver((entries, observer) => {
    entries
      .filter(entry => entry.isIntersecting && entry.intersectionRatio >= 0)
      .forEach(entry => {
        var $item = $(entry.target);
        $item.addClass('active');
        observer.unobserve(entry.target);
      });
      
    }, options);
    
    $('.profile-picture').each(function () {
      observer.observe(this);
    })
  
    $('.text-info-custom').each(function () {
      observer.observe(this);
    })
  
    $('.card-logo-list').each(function () {
      observer.observe(this);
    })

    $('.tl-one').each(function () {
      observer.observe(this);
    })
  
    $('.tl-two').each(function () {
      observer.observe(this);
    })

    $('.clients-logos-list').each(function () {
      observer.observe(this);
    })

    $('.skill').each(function () {
    observer.observe(this);
  })
  
  $('.paragraphs-text').each(function () {
    observer.observe(this);
  })
  
  $('.form-style').each(function () {
    observer.observe(this);
  })

  $('.info').each(function () {
    observer.observe(this);
  })


  //SMOOTH SCROLLING 

// Select all links with hashes
$('a[href*="#"]')
// Remove links that don't actually link to anything
.not('[href="#"]')
.not('[href="#0"]')
.click(function(event) {
  // On-page links
  if (
    location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
    && 
    location.hostname == this.hostname
  ) {
    // Figure out element to scroll to
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
    // Does a scroll target exist?
    if (target.length) {
      // Only prevent default if animation is actually gonna happen
      event.preventDefault();
      $('html, body').stop().animate({
        scrollTop: target.offset().top
      }, 1000, function() {
        // Callback after animation
        // Must change focus!
        var $target = $(target);
        $target.focus();
        if ($target.is(":focus")) { // Checking if the target was focused
          return false;
        } else {
          $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
          $target.focus(); // Set focus again
        };
      });
    }
  }
});


});

